<template>
  <div class="feed-price content d-flex flex-column gap-2">
    <div class="d-flex flex-column gap-2">
      <h4>
        <span>Feed Price </span>
        <span v-if="FeedTitle"> - {{ FeedTitle }} ({{ FeedNodeCount | format }})</span>
      </h4>
    </div>

    <div v-if="state === states.Import" class="d-flex flex-column gap-2">
      <div class="d-flex gap-1">
        <span>Paste text in one of the following formats:</span>
        <span><strong>EAN</strong></span>
        <span>or <strong>EAN PRICE</strong></span>
        <span>or <strong>EAN QTY PRICE</strong></span>
        <span>(separated by TAB)</span>
      </div>

      <b-form-textarea
        ref="importString"
        class="mt-3"
        v-model="importString"
        placeholder="EAN or EAN PRICE or EAN QTY PRICE"
        rows="10"
        max-rows="10"
        autofocus
        @paste.native="onPaste"
      ></b-form-textarea>
    </div>

    <div v-if="state === states.Processing">
      Processing..
    </div>

    <div v-if="state === states.Result" class="d-flex flex-column gap-2">
      <div class="d-flex gap-2 align-items-center">
        <b-btn
          variant="primary"
          size="sm"
          @click="reset"
          :disabled="isLoading"
        >
          Reset
        </b-btn>

        <b-btn
          variant="white"
          size="sm"
          @click="exportResults"
          :disabled="isLoading"
        >
          Export
        </b-btn>

        <button-select
          v-model="filters.issues"
          :options="options.issues"
          size="sm"
        />

        <div
          class="text-success font-weight-bold"
        >
          Success: {{ successCount | format }}
        </div>
        <div
          class="text-warning font-weight-bold"
          v-if="warningCount > 0"
        >
          Warning: {{ warningCount | format }}
        </div>
        <div
          class="text-danger font-weight-bold"
          v-if="errorCount > 0"
        >
          Error: {{ errorCount | format }}
        </div>
      </div>
      <div>
        <table class="table table-sm table-google">
          <thead>
            <tr>
              <th class="text-nowrap">EAN</th>
              <th class="text-nowrap">Import Price</th>
              <th class="text-nowrap">Feed Price</th>
              <th class="text-nowrap">Result</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="result in filteredResults"
              :key="result.EAN"
              :class="`feed-price-status-${result.result_status}`"
            >
              <td class="text-nowrap row-fit">{{ result.ean }}</td>
              <td class="text-nowrap row-fit text-right">{{ result.importPrice | format }}</td>
              <td class="text-nowrap row-fit text-right">{{ result.feedPrice | format }}</td>
              <td class="text-nowrap">{{ result.result_message }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'FeedPrice',
  components: {
    ButtonSelect,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    FeedID() {
      return this.$route.params.id;
    },
    FeedTitle() {
      return Object.prototype.hasOwnProperty.call(this.feedMeta, 'title') ? this.feedMeta.title : '';
    },
    FeedNodeCount() {
      return Object.prototype.hasOwnProperty.call(this.feedMeta, 'node_count') ? this.feedMeta.node_count : 0;
    },
    successCount() {
      return this.results.filter((result) => result.result_status === 'success').length;
    },
    warningCount() {
      return this.results.filter((result) => result.result_status === 'warning').length;
    },
    errorCount() {
      return this.results.filter((result) => result.result_status === 'error').length;
    },
    filteredResults() {
      return this.results.filter((result) => {
        if (this.filters.issues === 'yes' && result.result_status === 'success') return false;
        return true;
      });
    },
  },
  data() {
    return {
      loadingCount: 0,
      feedMeta: {},
      importString: '',
      results: [],
      state: 0,
      states: {
        Import: 0,
        Processing: 1,
        Result: 2,
      },
      filters: {
        issues: null,
      },
      options: {
        issues: [
          { text: 'All', value: null },
          { text: 'Issues', value: 'yes' },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.results = [];
      this.state = this.states.Import;
      this.importString = '';
    },
    fetchMeta() {
      this.loadingCount += 1;
      this.$http
        .get(`/feed/${this.FeedID}/meta`)
        .then((res) => {
          this.feedMeta = res.body.feedMeta;
        })
        .catch((err) => {
          alert(`Failed to fetch feed meta: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    exportResults() {
      this.loadingCount += 1;
      this.$http
        .post(`/feed_price/${this.FeedID}/exportResults`)
        .send({ results: this.filteredResults })
        .then((res) => {
          const link = document.createElement('a');
          link.href = res.body;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          alert(`Failed to export data: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    processImportString() {
      this.loadingCount++;
      this.state = this.states.Processing;
      this.$http
        .post(`/feed_price/${this.FeedID}`)
        .send({ importString: this.importString })
        .then((res) => {
          this.results = res.body;
          this.state = this.states.Result;
        })
        .catch((err) => {
          alert(`Failed to submit import string: ${err.response.text}`);
          this.state = this.states.Import;
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onPaste() {
      setTimeout(() => {
        this.processImportString();
      }, 0);
    },
  },
  created() {
    this.fetchMeta();
  },
};
</script>

<style lang="scss" scoped>
.feed-price {
  max-width: 750px;
}
.feed-price-status {
  &-success {
    border-color: #2ed573;
    background: #c3f3d7;
    color: #23ad5c;
  }
  &-warning {
    border-color: #ffa502;
    background: #ffdb9b;
    color: #ce8500;
  }
  &-error {
    border-color: #ff4757;
    background: #ffe0e3;
    color: #ff4757;
  }
}
</style>
